import { defineStore } from 'pinia';
import { useUserData } from './userData';

type Banner = {
    id?: number
    title?: string
    desc?: string
    image?: string
};

type Author = {
    avatar: string | null
    first_name: string
    id: number
    last_name: string
};

type arrImages = {
    id: number
    title: string
    image: string                     
};

type Top = {
    author: Author
    category: string
    color?: null | string
    desc?: string
    file: string
    format: string
    genre?: null | string
    height: string
    id: number
    materials: string
    name: string
    period: string
    width: string
};

type Best = {
    author: Author
    arrImages: arrImages[]
};

export const useHomePageData = defineStore('homepage', {
    state: () => {
        return {
            banner: [] as Banner[],
            top: [] as Top[],
            best: [] as Best[],
            loading: false
        }
    },
    actions: {
        async getBannerData() {
            try {
                this.loading = true;
                const response = await useApiFetch('/banners/', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json;',
                    },
                });
                if (response.data) {
                    return this.banner = response.data || [];
                }
            } catch (error) {
                console.error(error);
            } finally { 
                this.loading = false;
            }
        },
        async getTopData() {
            try {
                this.loading = true;
                const response = await useApiFetch('/pictures/', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json;',
                    },
                });
                if (response.data) {
                    const topTen = response.data.slice(0, 10);
                    return this.top = topTen || [];
                }
            } catch (error) {
                console.error(error);
            } finally { 
                this.loading = false;
            }
        },
        async getBestData() {
            try {
                this.loading = true;
                // const response = await useAsyncData(() => $fetch('/mock/authors'));
                const response = await useApiFetch('/profile/artists/', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json;',
                    },
                });
                if (response.data) {
                    const topTen = response.data.slice(0, 10);
                    return this.best = topTen || [];
                }
            } catch (error) {
                console.error(error);
            } finally { 
                this.loading = false;
            }
        },
    }
});
